import * as Sentry from "@sentry/gatsby";
import { useActions, useReduxState } from "re-reduced";
import React, { ReactNode, useEffect } from "react";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { createGlobalStyle } from "styled-components";
import "../../../static/fonts.css";

import actions from "@state/actions";
import * as selectors from "@state/selectors";
import {
  assets, fontSizes, PRIMARY_COLOR,
  SECONDARY_COLOR
} from "@util/constants";
import useOnlineStatus from "@util/hooks";
import {
  MediaPermissionsError, requestMediaPermissions
} from 'mic-check';
import Header from "./header";
import { useTelemetry } from "@util/telemertry";

interface Props {
  children: ReactNode;
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
    font-family: "bold";
    font-size: ${fontSizes.p.default}px;
    color: ${PRIMARY_COLOR};
    background-color: ${SECONDARY_COLOR};

    /* https://usefulangle.com/post/278/html-disable-pull-to-refresh-with-css#:~:text=Pull%2Dto%2Drefresh%20can%20be%20disabled%20using%20the%20overscroll%2D,a%20container%20is%20over%2Dscrolled. */
    /* Disable pull-to-refresh android */
    /* overscroll-behavior-y: contain; */
  }
  #gatsby-focus-wrapper {
    height: 100%;
  }

  .Toastify__toast {
    border-radius: 5px;
  }
  
  .Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.3s;
    // animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  .Toastify__toast-container {
    padding: 8px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
`;

const Wrapper = styled.div<{ hasBackground?: boolean }>`
  height: 100vh;
  ${({ hasBackground }) =>
    hasBackground &&
    `background-image: url(${assets.stadium});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat`}
`;

const Main = styled.main<{}>`
  overflow-x: hidden;
  height: 90vh;
  padding: 0 4px 8px;
`;

function Layout(props: Props) {

  const isOnline = useOnlineStatus();

  const { children } = props;
  const {
    setErrorMessage,
    updateOnline,
    syncPendingScans,
    setSuccessMessage,
    setCameraPermission,
    updatePendingScanCount
  } = useActions(actions);
  const {
    route,
    error,
    user,
    successMessage,
  } = useReduxState({
    route: selectors.getRoute,
    error: selectors.getError,
    user: selectors.getUser,
    successMessage: selectors.getSuccessMessage,
  });

  const track = useTelemetry();


  useEffect(() => {
    if (
      typeof navigator === "undefined" ||
      typeof navigator.mediaDevices === "undefined"
    ) {
      return;
    }

    track("Requesting media permissions", { devices: navigator.mediaDevices});

    requestMediaPermissions({ audio: false, video: true })
      .then((_result) => {
        console.log("Camera permissions granted")
        track("Camera permissions granted");
        setCameraPermission(true);
      })
      .catch((error: MediaPermissionsError) => {

        console.log("Camera permissions denied")
        track("Camera permissions denied", { error: error?.message });
        toast.error("Please enable camera permissions");

        Sentry.captureException(new Error(error.message), {
          tags: {
            type: error.type,
            name: error.name,
          }
        });
      });
  }, []);

  useEffect(() => {
    updatePendingScanCount();
  }, [])

  useEffect(() => {

    updateOnline(isOnline);

    if (isOnline) {
      syncPendingScans();
    } else {
      // TODO: show notification or not?
      // setErrorMessage("Disconnected from the internet");
    }
  }, [isOnline]);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        onClose: () => setErrorMessage(undefined),
      });
    }
    if (successMessage) {
      toast.success(successMessage, {
        delay: 300,
        onClose: () => setSuccessMessage(undefined),
      });
    }
  }, [error, user, successMessage]);


  return (
    <Wrapper hasBackground={route === "login"}>
      <GlobalStyle />
      {route !== "search-tickets" && <Header />}
      <Main>{children}</Main>
      <ToastContainer
        position="bottom-center"
        closeOnClick
        autoClose={2000}
        closeButton={false}
        theme="colored"
        transition={Slide}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </Wrapper>
  );
}

export default Layout;
